const state = () => ({
  permissions: [],
})

const getters = {
  getCountPermission (state) {
    return state.permissions.length
  },
  getAccessAddSaleShipping (state) {
    return state.permissions.includes('add_sale_shippings')
  },
  getAccessManageMemberAddress (state) {
    return state.permissions.includes('manage_member_addresses')
  },
  getAccessManageMutation (state) {
    return state.permissions.includes('manage_mutations')
  },
  getAccessManageSalePayment (state) {
    return state.permissions.includes('manage_sale_payments')
  },
  getAccessManageSaleRetur (state) {
    return state.permissions.includes('manage_sale_returs')
  },
  getAccessManageStockopname (state) {
    return state.permissions.includes('manage_stockopnames')
  },
  getAccessManageStockopnameSale (state) {
    return state.permissions.includes('manage_stockopname_sales')
  },
  getAccessCancelMutation (state) {
    return state.permissions.includes('cancel_mutation')
  },
  getAccessCancelSale (state) {
    return state.permissions.includes('cancel_sale')
  },
  getAccessGenerateReportCustomer (state) {
    return state.permissions.includes('generate_report_customer')
  },
  getAccessGenerateReportMutation (state) {
    return state.permissions.includes('generate_report_mutation')
  },
  getAccessGenerateReportMutationExternal (state) {
    return state.permissions.includes('generate_report_mutation_external')
  },
  getAccessGenerateReportSale (state) {
    return state.permissions.includes('generate_report_sale')
  },
  getAccessGenerateReportStock (state) {
    return state.permissions.includes('generate_report_stock')
  },
  getAccessGenerateReportStockAdjustment (state) {
    return state.permissions.includes('generate_report_stock_adjustment')
  },
  getAccessGenerateReportStockopname (state) {
    return state.permissions.includes('generate_report_stockopname')
  },
  getAccessGenerateReportStockopnameSale (state) {
    return state.permissions.includes('generate_report_stockopname_sale')
  },
  getAccessDecreaseStock (state) {
    return state.permissions.includes('decrease_stock')
  },
  getAccessValidateTransaction (state) {
    return state.permissions.includes('validate_transaction')
  },
  getAccessViewDashboard (state) {
    return state.permissions.includes('view_dashboard')
  },
  getAccessViewLogSale (state) {
    return state.permissions.includes('view_log_sale')
  },
  getAccessViewStockBranch (state) {
    return state.permissions.includes('view_stock_branch')
  },
  getAccessGenerateChart (state) {
    return state.permissions.includes('generate_chart')
  },
  getAccessChangeMutationTimestamp (state) {
    return state.permissions.includes('change_mutation_timestamp')
  },
}

const actions = {
  savePermission ({ commit }, permissions) {
    commit('setPermissions', permissions)
  },
}

const mutations = {
  setPermissions (state, permissions) {
    state.permissions = permissions
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}